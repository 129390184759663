import i18n from "@/plugins/i18n";

export function GetItemsTabs() {
    return [
        {
            key: 0,
            label: "report.tabs.config",
            disabled: false,
        },
        {
            key: 1,
            label: "report.tabs.scheduler",
            disabled: true,
        }
    ];
}

export function GetDataPanels(){
    return [
        GetDataPanelReportType(),
        GetDataPanelFilters(),
        GetDataPanelDimensions(),
        GetDataPanelMetrics()
    ]
}

export function GetDataPanelReportType(){
    return {
        id: 0,
        key: "report_type",
        title: i18n.t("report.report_type"), 
        required: false,
        expanded: true,
        showFilter: false,
        showClearFilter: false,
    }
    
}

export function GetDataPanelFilters(){
    return {
        id: 1,
        key: "filters",
        title: i18n.t("report.title.Filters"), 
        required: true,
        expanded: true,
        showFilter: true,
        showClearFilter: true,
        icon:{
            filter:{
                value: "mdi-arrow-expand",
                label: i18n.t("report.title.Filters")
            },
            clearFilter:{
                value: "mdi-filter-remove",
                label: i18n.t("report.title.remove_Filters")
            }
            
        }
    }
}

export function GetDataPanelDimensions(){
    return {
        id: 2,
        key: "dimensions",
        title: i18n.t("report.title.dimension"),
        required: true,
        expanded: true,
        showFilter: false,
        showClearFilter: false,
    }
}

export function GetDataPanelMetrics(){
    return {
        id: 3,
        key: "metrics",
        title: i18n.t("report.title.metrics"),
        required: true,
        expanded: true,
        showFilter: false,
        showClearFilter: false,
    }
}

export function FiltersLabels(){
    return {
        account: i18n.t("report.filters.account_id"),
        advertisers: i18n.t("report.filters.advertiser_id"),
        campaigns: i18n.t("report.filters.campaign_id"),
        creatives: i18n.t("report.filters.creative_id"),
        deal_id: i18n.t("report.filters.deal_id"),
        layer_name: i18n.t("report.filters.layer_name"),
        line_items: i18n.t("report.filters.line_item_id")
    }
}

export function GetButtonRunReport(){
    return {
        title: "report.title.generated_report",
        icon: "mdi-format-list-bulleted",
        loading: false
    }
}

export function GetConditionalMetrics(){
    return { 
        account_id: {
            selected:['account_name', 'timezone']
        },
        account_name:{
            selected:['account_id', 'timezone']
        },
        timezone: {
            selected:['account_name', 'account_id']
        },
        campaign_id:{
            selected:['campaign_name']
        },
        campaign_name:{
            selected:['campaign_id']
        },
        advertiser_id:{
            selected:['advertiser_name']
        },
        advertiser_name:{
            selected:['advertiser_id']
        },
        creative_id:{
            selected:['creative_name']
        },
        creative_name:{
            selected:['creative_id']
        },
        line_item_id:{
            selected:['line_item_name']
        },
        line_item_name:{
            selected:['line_item_id']
        }
    }
}