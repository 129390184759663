import Vue from "vue";

export default Vue.extend({
	name: "PaginatedChipsIndex",
	props: {
		items: {
			type: [Array, Object],
			default: function () {
				return [];
			},
		},
		groupStyles: {
			type: String,
			default: "",
		},
		groupClass: {
			type: String,
			default: "",
		},
		chipClass: {
			type: String,
			default: "",
		},
		canClose: {
			type: Boolean,
			default: true,
		},
		column: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: "",
		}
	},
	components: {},
	data: () => ({}),
	created() {},
	mounted() {
		this.$nextTick(async () => {});
	},
	computed: {
		displayedItems(): any[] {
			return this.items;
		},
		isItemsArray() {
			return Array.isArray(this.items);
		},
		showIfSingleOption() {
			return !!this.items && Object.keys(this.items).length > 0
		}
	},
	methods: {
		handleRemove(item: any) {
			this.$emit("remove", { type: this.type, item });
		}
	},
	watch: {},
});
