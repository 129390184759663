import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import DataPickerStarTime from "@/components/Content/DataPickerStarTime.vue";
import DatePicker from "@/components/Content/DatePicker.vue";
import CardAction from "@/components/Content/CardAction.vue";
import ArrayListItem from "@/components/Content/ArrayListItem.vue";
import SnackMessage from "@/components/Commons/Loaders/SnackMessage/index.vue";
import { ScheduleEntity } from "@/models/Reports/v2/Entity";
import { SnackNotificationEntity } from "@/models/Snack";
import { CardActionType } from "@/interfaces/action";
import { Status } from "@/interfaces/state";
import { TypeLoading } from "@/interfaces/loading";
import { ReportDataEntity, ResourceDataEntity } from "@/interfaces/report";
import { ScheduleResource } from "@/interfaces/reports/v2/scheduler";
import { DEFAULT_DATE_TIME_FORMAT } from "@/utils/reportData";
import {
	isAfterCompare,
	isMaxDays,
	isRequired,
} from "@/services/rule-services";
import { SnackNotification, SnactType } from "@/interfaces/snack";
import { getPermisionReport } from "@/utils/permissionResolve";
import { ResponseReportEntity } from "@/models/Reports/v2/GenerateReport";

@Component({
	components: {
		CardTextField,
		CardAutocomplete,
		DataPickerStarTime,
		DatePicker,
		CardAction,
		ArrayListItem,
		SnackMessage,
	},
	mixins: [],
})
export default class SchedulerForm extends Vue {
	/**
	 * GETTERS
	 */
	@Getter("report_v2/storeSchedule") getStoreSchedule!: ScheduleEntity;

	@Getter("report_v2/storeReport") getStoreReport!: ReportDataEntity;

	@Getter("profile/getAbility") getAbility;
	
	@Getter("report_v2/storeResources") getStoreResources!: ResourceDataEntity;
	
	@Getter("generate_report/attempResponse") attempResponse!: ResponseReportEntity;

	@Getter("account/isAccountMaster") isAccountMaster!: Boolean;

	/**
	 * DATA
	 */
	public valid: boolean = false;
	public isActionButtonLoading: boolean = false;
	public startDateScheduler: Date = new Date();
	public snackData: SnackNotificationEntity = new SnackNotificationEntity();

	/**
	 * Computed
	 */
	public get getMinDate() {
		return this.$moment().format(DEFAULT_DATE_TIME_FORMAT);
	}

	public get getMinDateEdit() {
		return this.getDateScheduler("schedule_start_date");
	}

	public get disabledData() {
		return {
			[CardActionType.CREATE]: !this.enableCreateSchedule,
			[CardActionType.UPDATE]: !this.enableEditSchedule,
		};
	}

	public get enableCreateSchedule(): boolean {
		return this.attempResponse.isSuccess() && this.canCreate;
	}

	public get enableEditSchedule(): boolean {
		return this.attempResponse.isSuccess() && this.canUpdate;
	}

	public get getBtnShows(): CardActionType[] {
		this.isActionButtonLoading = true;

		let buttons = [CardActionType.CANCEL];

		const actionType = this.isEdit
			? CardActionType.UPDATE
			: CardActionType.CREATE;

		buttons.push(actionType);

		this.isActionButtonLoading = false;

		return buttons;
	}

	public get getTextActions() {
		return {};
	}

	public get isEdit(): boolean {
		return this.getStoreSchedule.form.hasID();
	}

	public get isFail(): boolean {
		return this.verifyStatus(Status.FAIL);
	}

	public get getPermission(){
		return getPermisionReport();
	}

	public get canCreate(): boolean {
		return this.getAbility.can(this.getPermission.actions['create_scheduler'], this.getPermission.subject);
	}

	public get canUpdate(): boolean {
		return this.getAbility.can(this.getPermission.actions['update_scheduler'], this.getPermission.subject);
	}

	/**
	 * Actions
	 */

	public verifyStatus(status: Status) {
		return Status.FAIL === status;
	}

	private async dispatchStore<T>(moduleFunc: string, data?: T) {
		return await this.$store.dispatch(moduleFunc, data);
	}

	private async setLoadingData(actionType?: TypeLoading) {
		await this.dispatchStore("loading/setLoadingData", actionType);
	}

	private getDateScheduler(
		type: "schedule_start_date" | "schedule_end_date"
	) {
		const scheduleDate = this.getStoreSchedule.form[type];
		let dateMoment = scheduleDate
			? this.$moment()
			: this.$moment(scheduleDate);

		return dateMoment.format(DEFAULT_DATE_TIME_FORMAT);
	}

	/**
	 * Actions Handlers
	 * @param event
	 */
	public handleAppendEmailTo(event: any) {
		const email = this.getStoreSchedule.form.email_to;
		this.getStoreSchedule.form.appendEmail(email);
	}

	public handleDeleteEmailTo(email: string) {
		this.getStoreSchedule.form.removeEmail(email);
	}

	private handleResetData() {
		this.getStoreSchedule.resetForm();
		this.getStoreSchedule.form.resetForm();
		this.getStoreReport.setReportData();
		this.resetValidation();
		this.attempResponse.setSuccess(false);
	}

	public async handleAction(action: { type: string }): Promise<void> {
		switch (action.type) {
			case CardActionType.CANCEL:
				this.handleResetData();
				break;
			case CardActionType.CREATE:
				await this.handleSubmit(false);
				break;
			case CardActionType.UPDATE:
				await this.handleSubmit(true);
				break;
		}
	}

	private async validate(): Promise<boolean> {
		// Utilizar una afirmación de tipo para indicar a TypeScript que $refs.form tiene un método 'validate'
		const formComponent: any = this.$refs.form;

		if (formComponent && typeof formComponent.validate === "function") {
			return await formComponent.validate();
		}

		return false;
	}

	private async resetValidation(): Promise<boolean> {
		// Utilizar una afirmación de tipo para indicar a TypeScript que $refs.form tiene un método 'validate'
		const formComponent: any = this.$refs.form;

		if (
			formComponent &&
			typeof formComponent.resetValidation === "function"
		) {
			return await formComponent.resetValidation();
		}

		return false;
	}

	private async addRules() {
		const isAfter: any = isAfterCompare(
			this.getStoreSchedule.form.schedule_end_date,
			this.getStoreSchedule.form.schedule_start_date
		);
		const isMax: any = isMaxDays(
			new Date(this.getStoreSchedule.form.schedule_end_date),
			new Date(this.getStoreSchedule.form.schedule_start_date),
			90
		);
		this.getStoreSchedule.rules.schedule_end_date = [
			isRequired,
			isAfter,
			isMax,
		];
	}

	public async handleSubmit(isEdit: Boolean = false) {
		try {
			/**
			 * Se agregan reglas de validacion
			 */
			await this.addRules();

			/**
			 * Validar el formulario antes de continuar
			 */
			if (!(await this.validate())) return;

			/**
			 * Validar si existe al menos un email agregado
			 */
			if (!this.getStoreSchedule.form.hasEmail()) {
				return this.snackData.setSnackData({
					message: this.$t("report.scheduler.msgToEmail"),
					type: SnactType.ERROR,
				} as SnackNotification);
			}

			/**
			 * Establecer el estado de carga antes de realizar la acción
			 */
			await this.setLoadingData(TypeLoading.loading);

			const form = this.getStoreSchedule.form;

			const isOoh = this.getStoreReport.isOoh();

			const actionType = isEdit ? "updateSchedule" : "createSchedule";

			const payload = isEdit
				? form.getPayloadUpdate(isOoh)
				: form.getPayloadCreate(isOoh);
				
			await this.dispatchStore(`report_v2/${actionType}`, payload);

			this.handleResetData();

		} catch (error) {
			// Manejar errores durante el envío del formulario
			console.error("handleSubmit", { error: error });
		} finally {
			// Restablecer el estado de carga en caso de error
			await this.setLoadingData();
		}
	}

	async fetchResource<K extends keyof ScheduleResource>(type: K) {
		try {
			/**
			 * Obtener el module dependiendo del :type
			 */
			const dispatchModule =
				this.getStoreSchedule.resource.matcheResource(type);

			/**
			 * Obtener el recurso con el dispatch
			 */
			const result = await this.dispatchStore(dispatchModule);

			// Guardar datos en {resource}
			await this.getStoreSchedule.resource.setResource(type, result);
		} catch (error) {
			console.error("fetchResource", error);
		}
	}

	/** Life Cicle */
	public async mounted() {
		this.fetchResource("scheduler_type");
	}

	// @Watch("getStoreSchedule.form.id")
	// async onScheduleIdChange(val: boolean | null, old: boolean | null) {
	// 	await this.getBtnShows();
	// }
}
