import {
	FilterProperty,
	Filters,
	FiltersEntity,
	ResourceDataEntity,
} from "@/interfaces/report";
import { isProd } from "@/services/process-service";
import { isEmpty } from "lodash";

export class PayloadResource {
	response_filters: string = "";
	response_parsed: Record<string, any> = {};
	getStoreResources: ResourceDataEntity;

	constructor(filters: string, getStoreResources: ResourceDataEntity) {
		this.response_filters = filters;
		this.response_parsed = JSON.parse(filters);
		this.getStoreResources = getStoreResources;
		//this.debugLog();
	}

	filterResources(resources: any[], filterIds: any[], property: "key" | "value") {
		return resources?.filter((resource) =>
			filterIds?.some((id) => String(id) === String(resource[property]))
		);
	}

	filterSingleResources(resources: any[], id: number | null, property: "key" | "value"): FilterProperty | null {
		if (!id) return null;
		return resources?.find((resource) => String(id) === String(resource[property])) || null;
	}

	async applyFilters(): Promise<FiltersEntity> {
		const resources: ResourceDataEntity = this.getStoreResources;
		const parsed: Record<string, any> = this.response_parsed;

		// Parse identificadores de capas y nombres con valor predeterminado
		const parsedLayerIdFilter: number[] = parsed.layer_id_ooh_filter || [];
		const parsedLayerName: number[] = parsed.layer_name || [];

		// Determinar el nombre de la capa resultante
		const resultLayerName: number | null = !isEmpty(parsedLayerIdFilter) ? parsedLayerIdFilter[0] :
			!isEmpty(parsedLayerName) ? parsedLayerName[0] :
				null;

		// Asignar nombre de capa OOH y distancia
		const oohLayerName: number = parsed.layer_id_ooh_filter || parsed.ooh_layer_name;
		const oohDistance: number = parsed.ooh_distance;

		// Filtrar propiedades llamando a filterResources
		const filterResourcesByKey = (resource: FilterProperty[], parsedData: any): FilterProperty[] =>
			this.filterResources(resource, parsedData, "key");

		const filters: Partial<Filters> = {
			account: filterResourcesByKey(resources.account, parsed.account),
			advertisers: filterResourcesByKey(resources.advertisers, parsed.advertisers),
			campaigns: filterResourcesByKey(resources.campaigns, parsed.campaigns),
			line_items: filterResourcesByKey(resources.line_items, parsed.line_items),
			creatives: filterResourcesByKey(resources.creatives, parsed.creatives),
			deal_id: filterResourcesByKey(resources.deal_id, parsed.deal_id),
			layer_name: this.filterSingleResources(resources.layer_name, resultLayerName, "key"),
			ooh_layer_name: this.filterSingleResources(resources.ooh_layer_name, oohLayerName, "key"),
			ooh_distance: this.filterSingleResources(resources.ooh_distance, oohDistance, "key"),
		};

		// Crear y set de datos: FiltersEntity
		const filterEntity: FiltersEntity = new FiltersEntity(filters.account);
		filterEntity.setData(filters);

		return filterEntity;
	}

	debugLog() {
		if (isProd()) return;
		console.debug("[DEBUG]PayloadResource", {
			response_filters: this.response_filters,
			response_parsed: this.response_parsed,
			getStoreResources: this.getStoreResources,
			//applied: this.applyFilters(),
		});
	}
}
